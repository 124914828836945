@import "../../../../public/mixins//mixins.scss";

.statsWrapper {
  max-width: 1440px;
  padding: 0px 60px;
  margin: auto;
  @include mobile() {
    padding: 0px 20px;
  }

  .statsContainer {
    @include flexContainer(row, space-between, center, 0, 0, wrap);
    border-radius: 20px;
    border: 1px solid #cdcdcd;
    background: #fff;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 1.1, 0.1);
    padding: 10px 0px;
    @include mobile() {
      padding: 20px 0px 10px;
    }

    .statsNumbers {
      margin-left: 20px;
      flex: 1;
      padding: 0px 50px;
      @include flexContainer(row, space-around, unset, 0, 20px);
      @include mobile() {
        padding: 0px 20px;
      }
      .statsInfo {
        text-align: center;
        .statsCount {
          @include font(26px, 600, #4a536a, 1.04px);
          @include mobile() {
            font-size: 18px;
          }
        }
        .statsHeading {
          @include font(12px, 400, #4a536a, 0.48px);
          @include mobile() {
            font-size: 10px;
          }
          margin-top: 10px;
        }
      }
    }

    .verticalDevider {
      width: 1px;
      align-self: stretch;
      background-color: #eee;
    }

    .statsTagline {
      margin-top: 5px;
      text-align: center;
      flex: 1;
      padding: 0px 20px;
      @include font(22px, 300, #454f66, 0.88);
      @include mobile() {
        font-size: 13px;
      }
      .price {
        @include font(22px, 700, #007a5a, 0.88);
        @include mobile() {
          font-size: 15px;
        }
      }
    }
  }
}
