@import "../../../../public/mixins//mixins.scss";

.careBannerWrapper {
  @include flexContainer(row, unset, stretch);
  max-width: 1440px;
  padding: 0px 60px;
  margin: auto;
  @include mobile() {
    flex-wrap: wrap;
    padding: 0px 20px;
  }

  .careBannerInfoContainer {
    margin-top: auto;
    margin-bottom: 10px;
    padding: 40px 0px;
    padding-bottom: 0px;
    @include mobile() {
      padding: 20px 0px;
    }
    .careBannerInfo {
      @include mobile() {
        width: 90%;
      }
      .heading1 {
        @include font(14px, 400, #454f66, 0.98px, 30px, italic);
        @include mobile() {
          @include font(14px, 400, #454f66, 0.98px, 20px, italic);
        }
      }
      .heading2 {
        @include font(20px, 300, #454f66, 0.8px, 38px, italic);
        @include mobile() {
          @include font(16px, 300, #454f66, 0.8px, 28px, italic);
        }
      }
      .heading3 > h4 {
        margin: 0px;
        padding: 0px;
      }
      .heading3 {
        line-height: 38px;
        @include font(32px, 900, #454f66, 1.12px, 38px, italic);
        @include mobile() {
          @include font(20px, 600, #454f66, 1.12px, 28px, italic);
        }

        display: grid;
      }
      .heading4 > h1 {
        margin: 0px;
        padding: 0px;
        @include font(32px, 800, #006daf, 1.12px, 38px, italic);
        @include mobile() {
          @include font(20px, 600, #006daf, 1.12px, 28px, italic);
        }
        display: grid;
      }
    }

    .careBannerSearchWrapper {
      @include flexContainer(column, unset, unset, 12px);

      .heading5 {
        margin-top: 27px;
        @include font(14px, 400, #454f66, 0.56px);
        @include mobile() {
          font-size: 12px;
        }
      }

      .searchContainer {
        width: 100%;
        @include flexContainer(row, unset, unset, 10px, 14px);
        @include mobile() {
          flex-wrap: wrap;
        }

        .searchInputContainer {
          flex: 0 1 400px;
          @include mobile() {
            flex-grow: 1;
          }
          .searchInput {
            padding: 16px 24px 16px 24px;
            border-radius: 25px;
            border: 1px solid #666161;
            background: #fff;
            width: 100%;
            @include mobile() {
              padding: 12px;
            }
          }
        }

        .searchBtn {
          @include button(14px, 600, 15px 30px);
          @include mobile() {
            @include button(14px, 600, 12px 30px);
            width: 100%;
          }
        }
      }

      .heading6 {
        @include font(10px, 400, #454f66, 0.4px);
      }
    }

    .countriesContainer {
      max-width: 500px;
      @include mobile() {
        max-width: 340px;
        margin: auto;
      }

      .counryInfo {
        @include flexContainer(row, unset, center, 0px, 15px);
        @include font(12px, 400, #454f66, 0.48px);
        border-radius: 19px;
        border: 1px dashed #e2e5ed;
        padding: 6px;
        min-width: 115px;
        margin: 0px 3px;
      }
    }
  }
  .careBannerBg {
    z-index: -10;
    margin-top: auto;
    position: relative;
    flex: 1 1 800px;
    min-height: 300px; /* Set this to an appropriate value */
    width: 100%; /* Match the container */
    @include mobile() {
      height: 230px;
    }
  }
}

// ... existing styles ...

.search-results-modal {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  max-height: 500px;
  overflow-y: auto;
  z-index: 1000;

  .search-section {
    padding: 16px;
    border-bottom: 1px solid #edf2f7;

    &:last-child {
      border-bottom: none;
    }

    &.empty-section {
      padding: 12px 16px;
      background-color: #f8fafc;
    }

    .section-title {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #1a202c;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;

      svg {
        width: 20px;
        height: 20px;
        color: #006daf;
      }
    }

    .result-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .result-item {
      padding: 8px 12px;
      border-radius: 8px;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #f0f9ff;
      }

      .item-name {
        color: #2d3748;
        font-size: 14px;
        font-weight: 500;
      }

      .item-detail {
        color: #718096;
        font-size: 12px;
        margin-top: 2px;
      }
    }

    .empty-message {
      color: #718096;
      font-size: 13px;
      text-align: center;
      padding: 8px;
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;

      .spinner {
        width: 20px;
        height: 20px;
        border: 2px solid #e2e8f0;
        border-top-color: #006daf;
        border-radius: 50%;
        animation: spin 0.8s linear infinite;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f7fafc;
  }

  &::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 3px;

    &:hover {
      background: #a0aec0;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// ... rest of existing styles ...
